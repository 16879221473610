import { CartBaseController } from "./cart_base_controller";

/*
 * We only remove localStorage to empty the cart, we keep sessionStorage
 * in case people wants to do more shopping, and it will be removed then
 * the tab is closed.
 */
export default class extends CartBaseController {
  connect() {
    this.storage.clear();
  }
}
