import { CartBaseController } from './cart_base_controller'

/*
 * Retrieves shipping methods
 */
export default class extends CartBaseController {
  static targets = [ 'couponCodeInvalid', 'preDiscount', 'total' ]

  connect () {
    this.couponCode.addEventListener('input', event => {
      this.couponCode.parentElement.classList.remove('was-validated')
      this.couponCode.setCustomValidity('')
    })
  }

  get couponCode () {
    if (!this._couponCode) this._couponCode = this.element.elements.coupon_code

    return this._couponCode
  }

  get couponCodeInvalid () {
    return this.hasCouponCodeInvalidTarget ? this.couponCodeInvalidTarget : document.querySelector('#coupon-code-invalid')
  }

  get preDiscount () {
    return this.hasPreDiscountTarget ? this.preDiscountTarget : document.querySelector('#pre-discount')
  }

  get total () {
    return this.hasTotalTarget ? this.totalTarget : document.querySelector('#total')
  }

  set total (total) {
    this.total.innerHTML = total
  }

  async apply (event = undefined) {
    event?.preventDefault()
    event?.stopPropagation()

    const orderToken = this.token
    const coupon_code = this.couponCode.value
    const include = 'line_items'

    const response = await window.spree.cart.applyCouponCode({ orderToken }, { coupon_code, include })

    this.element.elements.forEach(x => x.disabled = true)

    if (response.isFail()) {
      this.couponCodeInvalid.innerHTML = response.fail().summary
      this.couponCode.setCustomValidity(response.fail().summary)
      this.couponCode.parentElement.classList.add('was-validated')

      this.element.elements.forEach(x => x.disabled = false)

      return
    }

    this.cart = response;
    this.total = response.success().data.attributes.total;
    this.preDiscount.classList.remove("d-none");
  }
}
