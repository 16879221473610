import "core-js/stable";
import "regenerator-runtime/runtime";
import { Controller } from "stimulus";
import { Liquid } from "liquidjs";

/*
 * Base controller, shared methods go here and other classes extend from
 * this.
 */
export class CartBaseController extends Controller {
  get spree() {
    return window.spree;
  }

  /*
   * The storage
   */
  get storage() {
    return window.localStorage;
  }

  /*
   * Temporary storage
   */
  get storageTemp() {
    return window.sessionStorage;
  }

  get cart() {
    return JSON.parse(this.storage.getItem("cart"));
  }

  set cart(response) {
    this.storage.setItem("cart", JSON.stringify(response.success()));
  }

  /*
   * The orderToken
   *
   * @return [String]
   */
  get token() {
    return this.storage.getItem("token");
  }

  /*
   * The bearerToken
   *
   * @return [String]
   */
  get bearerToken() {
    return this.storageTemp.getItem("bearerToken");
  }

  set bearerToken(token) {
    this.storageTemp.setItem("bearerToken", token);
  }

  /*
   * Liquid renderer
   *
   * @return Liquid
   */
  get engine() {
    if (!window.liquid) window.liquid = new Liquid();

    return window.liquid;
  }

  /*
   * Updates the item counter
   */
  counterUpdate() {
    const item_count = this.cart.data.attributes.item_count;

    window.dispatchEvent(
      new CustomEvent("cart:counter", { detail: { item_count } })
    );
    this.storage.setItem("cart:counter", item_count);
  }

  /*
   * Removes the brackets from the name or returns the name
   *
   * @return [String]
   */
  idFromInputName(input) {
    const matches = input.name.match(/\[([^\]]+)\]$/);

    return matches === null ? input.name : matches[1];
  }

  async handleFailure(response) {
    const data = { type: "primary" };
    let template = "alert";

    const fail = response.fail();

    switch (fail.name) {
      case "MisconfigurationError":
        data.content = fail.message;
        break;
      case "NoResponseError":
        data.content = window.site.i18n.alerts.no_response_error;
        break;
      case "SpreeError":
        data.content = window.site.i18n.alerts.spree_error;
        break;
      case "BasicSpreeError":
        // XXX: The order is missing, we need to start a new one
        if (fail.serverResponse.status === 404) {
          template = "recover_order";
          data.content = window.site.i18n.alerts.recover_order;
        } else {
          data.content = response.fail().summary;
        }

        break;
      case "ExpandedSpreeError":
        const content = [];
        // XXX: La API devuelve una combinación de errores.
        data.content = this.combineErrors(fail.errors).join(". ");

        break;
      default:
        data.content = fail.message;
    }

    console.error(fail.name, data.content);

    window.dispatchEvent(
      new CustomEvent("notification", { detail: { template, data } })
    );
  }

  combineErrors(errorsObject) {
    const combinedErrors = [];

    for (const key in errorsObject) {
      const value = errorsObject[key];
      let translatedKey = window.site.i18n.errors[key];

      if (!translatedKey) {
        console.error("No hay traducción", key);
        translatedKey = key;
      }

      switch (value.constructor.name) {
        case "Object":
          for (const subValue of this.combineErrors(value)) {
            combinedErrors.push(`${translatedKey} ${subValue}`);
          }
          break;

        case "String":
          combinedErrors.push(`${translatedKey} ${value}`);
          break;

        case "Array":
          for (const item of value) {
            combinedErrors.push(`${translatedKey} ${item}`);
          }
          break;
      }
    }

    return combinedErrors.flat();
  }
}
