import { CartBaseController } from "./cart_base_controller";

/*
 * Renders the order table.  All products are stored on localStorage, so
 * we just fetch that information and render the cart contents using
 * Liquid.
 */
export default class extends CartBaseController {
  static targets = ["cart", "subtotal"];

  async connect() {
    const products = this.products;
    const site = window.site;

    this.render({ products, site });
    this.subtotalUpdate();
    this.subscribe();
  }

  /*
   * Subscribe to change on the storage to update the cart.
   */
  subscribe() {
    window.addEventListener("storage", async (event) => {
      if (!event.key?.startsWith("cart:item:")) return;

      const products = this.products;
      const site = window.site;

      this.render({ products, site });
      this.subtotalUpdate();
    });

    window.addEventListener("cart:subtotal:update", (event) =>
      this.subtotalUpdate()
    );
  }

  /*
   * Gets all products from storage
   *
   * @return [Array]
   */
  get products() {
    if (!this.cart) return [];

    return this.cart.data.relationships.variants.data
      .map((x) => JSON.parse(this.storage.getItem(`cart:item:${x.id}`)))
      .filter((x) => x !== null);
  }

  /*
   * Download the item template and render the order
   */
  render(data = {}) {
    fetch(this.data.get("itemTemplate"))
      .then((r) => r.text())
      .then((template) => {
        this.engine
          .parseAndRender(template, data)
          .then((html) => (this.cartTarget.innerHTML = html));
      });
  }

  /*
   * Updates the subtotal
   *
   * XXX: This also updates the currency
   */
  subtotalUpdate() {
    if (!this.cart) return;

    this.subtotalTarget.innerText =
      this.cart.data.attributes.subtotal +
      " " +
      this.cart.data.attributes.currency;
  }
}
