import { Controller } from "stimulus";

/*
 * Al navegar por el sitio y llegar a ciertas secciones, se van
 * activando ítems del menú.
 *
 * Para eso configuramos un IntersectionObserver en todo el documento y
 * a medida que van apareciendo secciones actualizamos el menú.
 */
export default class extends Controller {
  static targets = ["section"];

  connect() {
    for (const section of this.sectionTargets) {
      this.observer.observe(section);
    }
  }

  /*
   * Solo nos interesa la primera
   */
  get observer() {
    if (!this._observer)
      this._observer = new IntersectionObserver(
        (entries, observer) => this.update(entries),
        this.options
      );

    return this._observer;
  }

  get options() {
    if (!this._options) this._options = { threshold: 0, rootMargin: "0px" };

    return this._options;
  }

  update(entries) {
    const section = entries.find((x) => x.isIntersecting);

    if (!section) return;

    window.dispatchEvent(
      new CustomEvent("scroll:section", { detail: { id: section.target.id } })
    );
  }
}
