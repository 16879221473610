import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    window.addEventListener("toast", (event) => {
      this.contentTarget.innerText = event.detail.content;
      this.element.classList.toggle("hide");
      this.element.classList.toggle("show");

      setTimeout(() => {
        this.element.classList.toggle("hide");
        this.element.classList.toggle("show");
      }, 3000);
    });
  }
}
