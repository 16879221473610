import { CartBaseController } from "./cart_base_controller";

/*
 * Register or login users.
 *
 * It brings shipping and billing addresses from the account.
 */
export default class extends CartBaseController {
  static targets = ["form", "username"];

  connect() {
    if (!this.hasFormTarget) return;

    this.formTarget.addEventListener("focusout", (event) =>
      this.formTarget.classList.add("was-validated")
    );

    // We only update the email on the order
    // https://github.com/spree/spree-storefront-api-v2-js-sdk/issues/146
    this.formTarget.elements.forEach((i) => {
      i.addEventListener("change", (event) =>
        this.storageTemp.setItem(this.idFromInputName(i), i.value)
      );
      i.value = this.storageTemp.getItem(this.idFromInputName(i));
    });
  }

  notify(data = {}) {
    window.dispatchEvent(
      new CustomEvent("notification", { detail: { template: "alert", data } })
    );
  }

  toggle() {
    this.usernameTarget.disabled = !this.usernameTarget.disabled;
  }

  enable() {
    this.usernameTarget.disabled = false;
  }

  disable() {
    this.usernameTarget.disabled = true;
  }
}
