import { Notifier } from "@airbrake/browser";

window.airbrake = new Notifier({
  projectId: window.env.AIRBRAKE_PROJECT_ID,
  projectKey: window.env.AIRBRAKE_PROJECT_KEY,
  host: "https://panel.sutty.nl",
});

import "core-js/stable";
import "regenerator-runtime/runtime";

import * as Turbo from "@hotwired/turbo";
Turbo.start();

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

import { makeClient } from "@spree/storefront-api-v2-sdk";
import { Sutty } from "./endpoints/sutty";

window.spree = makeClient({ host: window.env.SPREE_URL });
window.spree.sutty = new Sutty(window.spree.host);

try {
  //  window.axe = require('axe-core/axe')
} catch (e) {}

if (window.axe)
  window.axe.configure({ locale: require("axe-core/locales/es.json") });

document.addEventListener("turbo:load", (event) => {
  // Los links externos se abren por fuera de la app
  document
    .querySelectorAll("a[href^='http://'],a[href^='https://'],a[href^='//']")
    .forEach((a) => {
      if (a.href.startsWith(window.location.origin)) return;

      a.rel = "noopener noreferrer";
      a.target = "_blank";
    });

  if (!window.axe) return;

  window.axe.run().then((results) => {
    results.violations.forEach((violation) => {
      violation.nodes.forEach((node) => {
        node.target.forEach((target) => {
          document.querySelectorAll(target).forEach((element) => {
            element.classList.add("inaccesible");
            element.ariaLabel = node.failureSummary;
          });
        });
      });
    });
  });
});
